import React, { useEffect } from "react";
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

export default function Tools() {

  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render

  
  return (
    <Layout>
      <div className="relative top-32">
        <div className=" centered-content  ring-2 ring-orange-500 bg-black bg-opacity-60 overflow-hidden rounded-2xl backdrop-blur-sm">
          <div className="">
            <div className="absolute h-60 bg-gradient-to-b from-black to-transparent to-50% w-full">
            </div>
            <div className="p-lg flex flex-row items-center justify-center pb-2xl">
              {/* grupo LED MATRIX */}
              <div className="hover:scale-75  ease-in duration-150 w-40 h-40">
                <div className=" flex flex-col  w-52  bg-black rounded-3xl bg-opacity-60  ring-1 ring-orange-500">
                  <div>
                    <Link to="/matrix" ><StaticImage className="hover:rotate-12 ml-4 mr-4 mt-2 mb-2 hover:scale-150 ease-in duration-150 overflow-hidden" src="../images/matrix-tool.svg" alt="matrix" placeholder="blurred" width={142}/></Link>
                  </div>
                  <div className="mb-2 -mt-2 font-sans text-orange-100">
                    LED Matrix UNO R4
                  </div>
                </div>
              </div>
              {/* grupo LED MATRIX */}
              {/* grupo RGB */}
              <div className="hover:scale-75  ease-in duration-150 w-40 h-40">
                <div className=" flex flex-col  w-52  bg-black rounded-3xl bg-opacity-60  ring-1 ring-orange-500">
                  <div>
                    <Link to="/rgb-converter" ><StaticImage className="hover:rotate-12 ml-4 mr-4 mt-2 mb-2 hover:scale-150 ease-in duration-150 overflow-hidden" src="../images/color-rgb16-rgb24.svg" alt="matrix" placeholder="blurred" width={142}/></Link>
                  </div>
                  <div className="mb-2 -mt-2 font-sans text-orange-100">
                    RGB24 to RGB16
                  </div>
                </div>
              </div>
              {/* grupo RGB */}
              {/* grupo RESISTOR */}
              <div className="hover:scale-75  ease-in duration-150 w-40 h-40">
                <div className=" flex flex-col  w-52  bg-black rounded-3xl bg-opacity-60  ring-1 ring-orange-500">
                  <div>
                    <Link to="/resistor" ><StaticImage className="hover:rotate-12 ml-4 mr-4 mt-2 mb-2 hover:scale-150 ease-in duration-150 overflow-hidden" src="../images/resistor.svg" alt="resistores" placeholder="blurred" width={142}/></Link>
                  </div>
                  <div className="mb-2 -mt-2 font-sans text-orange-100">
                    Resistores
                  </div>
                </div>
              </div>
              {/* grupo RESISTOR */}
            </div>
          </div>
        </div>
        
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => (
  <SEO
    title={"Toolbox"}
    description={"Ferramentas desenvolvidas para makers"}
    pathname={"toolbox/"}
    children={""}
    publishedAt={"2023-09-01"}
    pageType={"CollectionPage"}
    thumbnail={"/manualdomaker-toolbox.png"}
  />
);
